<template>
    <section class="home">

        <Hero/>

        <section class="summary">
            <div class="container">
                <h2>Hello! I'm Joseph</h2>
                <p>
                    I'm just an ordinary person who appreciates a good challenge.
                    Young professional, who often takes on assignments beyond his capabilities
                    while enjoying the struggles and learning processes that comes with it.
                </p>
            </div>
        </section>

        <!-- Experience -->
        <timeline :section-name="'experience'" :history="experience"/>

        <!-- Education -->
        <timeline :section-name="'education'" :history="education"/>

        <!-- Skills -->
        <skills/>

        <!-- Contact Me -->
        <contact/>

        <!-- Footer -->
        <Footer/>

    </section>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Timeline from '@/components/Timeline';
import Skills from '@/components/Skills';
import Contact from '@/components/Contact';
import Footer from '@/components/Footer';

export default {
    name: 'Home',
    components: {
        Hero,
        Timeline,
        Skills,
        Contact,
        Footer,
    },
    data: () => {
        return {
            experience: [
                {
                    time: 'Dec 2019 - Present',
                    caption: 'Backend Developer',
                    institution: 'TALPA GAMING',
                    address: 'Amsterdam Area, Netherlands',
                    highlights: [
                        'Develop and maintain casual gaming portals',
                        'Develop and maintain Content Management System',
                        'Design and implement infrastructure needed for the portals and its services',
                        'Supports Content Manager and Operations Manager solving daily problems related to the portals',
                    ]
                },
                {
                    time: 'Sep 2018 - Dec 2019',
                    caption: 'Backend Developer',
                    institution: 'Azerion',
                    address: 'Amsterdam Area, Netherlands',
                    highlights: ['same as above :)']
                },
                {
                    time: 'Feb 2018 - Jul 2018',
                    caption: 'Internship',
                    institution: 'OrangeGames',
                    address: 'Amsterdam Area, Netherlands',
                    highlights: [
                        'Designed and developed popularity tracking system for games on company\'s gaming portals'
                    ]
                },
                {
                    time: 'Dec 2016 - Feb 2017',
                    caption: 'Internship',
                    institution: 'Faspay',
                    address: 'Jakarta, Indonesia',
                    highlights: [
                        'Designed and developed company\'s Customer Management System'
                    ]
                }
            ],
            education: [
                {
                    time: 'Sep 2017 - Jul 2018',
                    caption: 'Bachelor of Science',
                    institution: 'FONTYS UNIVERSITY OF APPLIED SCIENCES',
                    address: 'Eindhoven, Netherlands',
                    highlights: [
                        'Graduated with honours - Cum Laude',
                    ]
                },
                {
                    time: 'Aug 2014 - Aug 2018',
                    caption: 'Bachelor of Computer Science',
                    institution: 'PETRA CHRISTIAN UNIVERSITY',
                    address: 'Surabaya, Indonesia',
                    highlights: [
                        'Graduated with honours - Summa Cum Laude',
                        'Appreciation to University Students with the Best 10% Academic Achievement in 2014/2015',
                    ]
                },
            ]
        };
    },
    mounted () {},
    beforeDestroy() {},
    methods: {},
}
</script>

<style lang="scss">

.summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $dark-white;

    p {
        font-size: 18px;
    }

    .container {
        margin: 7rem;
        max-width: 535px;
        text-align: center;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media screen and (max-width: $mobile) {
            margin: 5rem;
        }
    }
}

</style>
