<template>
    <section id="skills" class="skills">
        <div class="header">
            <h2 class="container">Skills</h2>
        </div>

        <div class="container">
            <div class="skill-card" v-for="(item, i) in skills" :key="i">
                <div class="wrapper">
                    <div class="title">
                        <h2>{{item.title}}</h2>
                        <h3 class="sub-title">{{ item.shortDescription }}</h3>
                    </div>
                    <div class="skill-graph" v-if="item.percentage">
                        <div class="percentage">
                            <h4>{{ item.percentage }}<span>%</span></h4>
                        </div>
                        <svg>
                            <circle cx="50%" cy="75" r="70"/>
                            <circle cx="50%" cy="75" r="70" class="progress-bar" :data-percentage="item.percentage"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="skill-card">
                <div class="wrapper">
                    <div class="title">
                        <h2>Others</h2>
                        <h3 class="sub-title">Want to know more?
                            <router-link :to="{ name: 'others' }" replace>
                                Click me :)
                            </router-link>
                        </h3>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
export default {
    name: 'Skills',
    data: () => {
        return {
            skills: [
                {
                    percentage: '95',
                    title: 'Javascript',
                    shortDescription: 'Using it on a daily basis!'
                },
                {
                    percentage: '95',
                    title: 'SQL',
                    shortDescription: 'Quite proficient, have been using this since I start learning how to program.'
                },
                {
                    percentage: '80',
                    title: 'PHP',
                    shortDescription: 'Had sufficient experience, but not using it often enough to follow recent versions.'
                },
                {
                    percentage: '80',
                    title: 'HTML + CSS',
                    shortDescription: 'Obviously \u2665'
                },
                {
                    percentage: '10',
                    title: 'Golang',
                    shortDescription: 'Total beginner (for now)'
                },
                {
                    percentage: '30',
                    title: 'Python',
                    shortDescription: 'Small projects only, fun to learn and play with.'
                },
                {
                    percentage: '50',
                    title: 'Java',
                    shortDescription: 'Made a web app once using Spring framework. Meh ...'
                },
                {
                    percentage: '70',
                    title: 'Swift',
                    shortDescription: 'Made a health and fitness app once, couple years ago ...'
                },
                {
                    percentage: '50',
                    title: 'C / C# / C++',
                    shortDescription: 'University years, yay!'
                },
            ]
        };
    },
    mounted () {
        this.startProgress();
    },
    methods: {
        startProgress () {
            const arr = document.querySelectorAll('.progress-bar');
            for (let i = 0; i < arr.length; i++) {
                const bar = arr[i];
                const percentage = parseInt(bar.getAttribute('data-percentage'), 10);
                // Todo: set interval
                bar.style.strokeDashoffset = 440 - (440 * percentage)/100;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.skills {
    padding-top: 3rem;

    h2 {
        @include h2-section-header($white);
    }

    .header {
        background: $color-theme;
        padding: 1px;
        box-sizing: border-box;

        h2 {
            padding:0;
        }
    }

    .container {
        margin: 2.5rem 7rem;
        color: $white;
    }

    .skill-card {
        color: $color-theme;
        //cursor: pointer;

        width: 300px;
        height: 300px;
        min-width: 300px;
        min-height: 300px;

        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        box-sizing: border-box;

        margin: .5rem;
        padding: 1rem;

        h2 {
            color: $color-theme;
        }

        h3 {
            font-weight: 200;
        }

        .wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            justify-content: flex-end;
            .title {
                > h2 {
                    padding-top: 0;
                }
                box-sizing: border-box;
                padding: 1rem;
            }
            .sub-title {

            }
        }

        .skill-graph {
            display: block;
            box-sizing: border-box;
            position: relative;
            max-width: 100%;
            height: auto;

            svg {
                //cursor: unset;
                max-width: 100%;
                transform: rotate(90deg);

                circle {
                    fill: none;
                    stroke-width: 10;
                    stroke-dasharray: 440;
                    stroke-dashoffset: 440;
                    stroke-linecap: round;

                    &:nth-child(1) {
                        stroke: $dark-white;
                        stroke-dashoffset: 0;
                    }

                    &:nth-child(2) {
                        stroke: $color-theme;
                        stroke-dashoffset: calc(440 - (440 * 95) / 100);
                    }
                }
            }

            .percentage {
                display: flex;
                width: 100%;
                height: auto;
                position: absolute;
                top: 35%;
                justify-content: center;
            }
        }

        &:hover {
            transform: translateY(-5px) scale(1.005) translateZ(0);
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }
    }

}
</style>
