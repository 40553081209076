<template>
    <section :id="sectionName" class="timeline-section">
        <h2 class="container">{{ sectionName }}</h2>

        <div class="container">
            <div class="timeline">
                <ul>
                    <li v-for="(item, i) in history" :key="i">
                        <div class="title">
                            <strong>{{ item.time }}</strong>
                            <p>{{ item.caption }}</p>
                        </div>
                        <div class="content">
                            <label>
                                <span class="company">{{ item.institution }}</span>
                                | {{ item.address }}
                            </label>
                            <p v-for="(highlight, j) in item.highlights" :key="j">
                                {{ highlight }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Timeline',
    props: {
        sectionName: {
            type: String,
            required: true,
        },
        history: {
            type: Array,
            required: true,
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../sass/timeline';
.timeline-section {

    h2 {
        @include h2-section-header($color-theme);
    }

    h2:after{
        @include h2-underline;
    }

}
</style>
