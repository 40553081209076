<template>
    <div class="socials" :class="isContact === true ? 'contact-item' : ''">
        <span v-for="(item, i) in socials"
              :key="i"
              @click="goTo(item.src)">
            <i :class="'fab ' + item.ico"/>
        </span>
    </div>
</template>
<script>
export default {
    name: 'Socials',
    props: {
        isContact: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data: () => {
        return {
            socials : [
                {
                    src: 'https://www.linkedin.com/in/joseph-budiarto/',
                    ico: 'fa-linkedin-in',
                },
                {
                    src: 'https://www.instagram.com/josephbudiarto/feed/',
                    ico: 'fa-instagram',
                },
            ]
        };
    },
    methods: {
        goTo (target) {
            window.open(target, '_blank');
        }
    },
}
</script>

<style lang="scss" scoped>
svg {
    cursor: pointer;
}
</style>
