<template>
    <section id="contact" class="contact">
        <h2 class="container">Contact me</h2>

        <div class="container">
            <div class="form-container">
                <form id="form"
                      name="form"
                      method="POST"
                      @submit.prevent="checkForm">
                    <div class="form-group special-input">
                        <label for="firstName">First Name</label>
                        <input type="text" id="firstName" name="firstName"
                               :class="errorMessages.firstName !== '' ? 'isError' : ''"
                               @input="toggleError('firstName')"
                               v-model="firstName"/>
                        <label class="error-message">{{ errorMessages.firstName }}</label>
                    </div>
                    <div class="form-group special-input">
                        <label for="lastName">Last Name</label>
                        <input type="text" id="lastName" name="lastName" v-model="lastName"/>
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" id="email" name="email"
                               :class="errorMessages.email !== '' ? 'isError' : ''"
                               @input="toggleError('email')"
                               v-model="email"/>
                        <label class="error-message">{{ errorMessages.email }}</label>
                    </div>
                    <div class="form-group">
                        <label for="message">Type your message here ...</label>
                        <textarea id="message" name="message" rows="10"
                                  :class="errorMessages.message !== '' ? 'isError' : ''"
                                  @input="toggleError('message')"
                                  :maxlength="messageMaxLength"
                                  v-model="message"/>
                        <label class="error-message">{{ errorMessages.message }}</label>
                    </div>
                    <div class="form-group submit">
                        <button ref="submitButton" type="submit" id="submit" name="submit">
                            <span ref="submitText" id="submitText">Submit</span>
                            <span ref="spinner" id="spinner">
                                <i class="fas fa-cog"/>
                            </span>
                        </button>
                        <label>
                            Thanks for submitting!
                        </label>
                    </div>
                </form>
            </div>
            <div class="info">
                <div class="contact-title">
                    <h2>Joseph Budiarto</h2>
                    <h3>Backend Developer - and everything else</h3>
                </div>

                <div class="contact-item">
                    <strong>Phone:</strong>
                    <p>+31 614 657 375</p>
                </div>

                <div class="contact-item">
                    <strong>Email:</strong>
                    <p>
                        <a href="mailto:budiartojoseph@gmail.com">
                            budiartojoseph@gmail.com
                        </a>
                    </p>
                </div>

                <div class="contact-item">
                    <a href="/resume/josephbudiarto.pdf" download>
                        Download my resume :)
                    </a>
                </div>

                <socials :is-contact="true"/>
            </div>
        </div>

    </section>
</template>
<script>
import Socials from './Socials';
import axios from 'axios';

export default {
    name: 'Contact',
    components: {
        Socials,
    },
    data: () => {
        return {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            messageMaxLength: 450,
            errorMessages: {
                firstName: '',
                email:'',
                message: '',
            },
            /* eslint-disable */
            key: '6LdPpy4bAAAAAK6LBkPcEFEHcoLZqgYwXQrfNMV9'
            /* eslint-enable */
        };
    },
    methods: {
        checkForm () {
            let isError = false;

            if (this.firstName === '') {
                isError = true;
                this.errorMessages.firstName = 'First name cannot be empty!';
            }

            if (this.email === '') {
                isError = true;
                this.errorMessages.email = 'Please provide your email address!';
            } else if (!this.emailRegex(this.email)) {
                isError = true;
                this.errorMessages.email = 'Please provide a valid email address!';
            }

            if (this.message === '') {
                isError = true;
                this.errorMessages.message = 'Message cannot be empty!';
            } else if (this.message.length < 5) {
                isError = true;
                this.errorMessages.message = 'Message too short!';
            } else if (this.message.length > this.messageMaxLength) {
                isError = true;
                this.errorMessages.message = 'Message too long :(';
            }

            if (!isError) {
                this.sendMail();
            }
        },
        async sendMail () {
            let isSuccess = false;
            this.firstName = this.mysql_real_escape_string(this.firstName);
            this.lastName = this.mysql_real_escape_string(this.lastName);
            this.email = this.mysql_real_escape_string(this.email);
            this.message = this.mysql_real_escape_string(this.message);

            this.$refs.submitButton.disabled = true;
            this.$refs.submitText.style.display = 'none';
            this.$refs.spinner.style.display = 'block';

            try {
                await this.captchaIsReady();
                const token = await window.grecaptcha.execute(this.key, {
                    action: 'submit'
                });
                const form = document.querySelector('form');
                const data = new FormData(form);
                await axios.post('/submit.php', data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token,
                        },
                    }
                );
                isSuccess = true;
            } catch (err) {
                this.$tostini({
                    message: 'Oops something went wrong while sending the message!',
                    duration: 3000,
                    type: 'error'
                });
            }

            setTimeout(() => {
                this.$refs.submitButton.disabled = false;
                this.$refs.submitText.style.display = 'block';
                this.$refs.spinner.style.display = 'none';
            }, 1500);

            if (isSuccess) {
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.message = '';

                this.$tostini({
                    message: 'Email sent successfully',
                    duration: 3000,
                    type: 'success'
                });
            }
        },
        captchaIsReady () {
            return new Promise((resolve, reject) => {
                if (!window.grecaptcha) reject('captcha not loaded!');
                window.grecaptcha.ready(async () => {
                    resolve();
                });
            });
        },
        toggleError (field) {
            this.errorMessages[field] = '';
        },
        emailRegex (email) {
            const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (emailPattern.test(email));
        },
        mysql_real_escape_string(str) {
            // eslint-disable-next-line no-control-regex
            return str.replace(/[\0\x08\x09\x1a\n\r"'\\%]/g, (char) => {
                switch (char) {
                    case "\0":
                        return "\\0";
                    case "\x08":
                        return "\\b";
                    case "\x09":
                        return "\\t";
                    case "\x1a":
                        return "\\z";
                    case "\n":
                        return "\\n";
                    case "\r":
                        return "\\r";
                    case "\"":
                    case "'":
                    case "\\":
                    case "%":
                        return "\\" + char;
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
textarea {
    width:100%;
    resize: vertical;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 10px;
    font-family:inherit;
}

.contact {
    background: $dark-white;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    h2 {
        @include h2-section-header($color-theme);
    }

    h2:after {
        @include h2-underline($white);
    }

    .form-container {
        width: 50%;
        height: auto;

        padding: 1rem 3rem;
        box-sizing: border-box;

        @media screen and (max-width: $tablet) {
            width: 100%;
            padding: 1rem 0;
        }

        form {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .isError {
            border-color: $red;
        }

        .error-message {
            color: $red;
            font-size: $size-4;
        }

        .submit {
            text-align: center;
            label {
                font-size: $size-4;
                color: $color-theme;
            }
        }

        .form-group {
            box-sizing: border-box;
            padding: 0 3px;
            width: 100%;
            margin: 10px 0;

            &.special-input {
                width: 50%;
                @media screen and (max-width: $tablet) {
                    width: 100%;
                }
            }
        }

        #spinner {
            display: none;
            animation: spin 2s linear infinite;

            svg {
                margin: 0;
            }
        }
    }

    .info {
        width: 50%;
        height: auto;

        padding: 1rem 3rem;
        box-sizing: border-box;

        @media screen and (max-width: $tablet) {
            width: 100%;
            padding: 1rem 0;
        }

        @media screen and (max-width: $mobile){
            text-align: center;
        }

        @media screen and (min-width: $tablet) {
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                width: 2px;
                height: 60%;
                background: $white;
            }
        }

        .contact-title {
            h2 {
                padding: 0;
                text-transform: none;
                letter-spacing: unset;
            }
            margin-bottom: 2rem;
        }

        .contact-item {
            p {
                margin-top: 0;
                margin-bottom: 2rem;
            }
        }

        .socials {
            font-size: $size-2;
            border-top: 1px solid $line-color;
            margin-top: 11rem;
        }
    }
}
</style>
