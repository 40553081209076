<template>
    <section id="hero" class="hero">
        <div class="hero-container parallax">

            <div class="card card-left" role="img" aria-label="Joseph Budiarto"/>

            <div class="card card-right">
                <div class="card-wrapper">
                    <div class="card-title">
                        <h2>Joseph Budiarto</h2>
                        <h3>Backend Developer - and everything else</h3>
                    </div>

                    <div class="card-item">
                        <strong>Phone</strong>
                        <p>+31 614 657 375</p>
                    </div>

                    <div class="card-item">
                        <strong>Email</strong>
                        <p>
                            <a href="mailto:budiartojoseph@gmail.com">
                                budiartojoseph@gmail.com
                            </a>
                        </p>
                    </div>

                    <div class="card-item">
                        <strong>Location</strong>
                        <p>Amsterdam Nieuw-West, North Holland, Netherlands</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <socials/>
        </div>
    </section>
</template>
<script>
import Socials from './Socials';
export default {
    name: 'Hero',
    components: {
        Socials,
    },
    mounted () {

        const webP = new Image();
        webP.src = 'data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA';

        const loadJPGFallback = function (event) {
            window.webpSupport = event && event.target && event.target.height === 2;
            const parallax = document.querySelector('.parallax');
            const cardLeft = document.querySelector('.card-left');

            if (!window.webpSupport) {
                parallax.classList.add('no-webp');
                cardLeft.classList.add('no-webp');
            } else {
                parallax.classList.add('webp');
                cardLeft.classList.add('webp');
            }

            webP.removeEventListener('load', loadJPGFallback,false);
            webP.removeEventListener('error', loadJPGFallback,false);
        };
        webP.addEventListener('load', loadJPGFallback, { once: true });
        webP.addEventListener('error', loadJPGFallback, { once: true });
    }
};
</script>

<style lang="scss" scoped>
.hero {
    margin-top: 4.5rem;
    width: 100%;
    background-color: #F1F1F1;
    .hero-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .socials {
            background-color: #2F3D36;
            width: 950px;
            height: 75px;
            color: #F1F1F1;
            font-size: 25px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>
